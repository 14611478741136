<template>
  <div class="row">
    <div class="col-12">
      <!-- 평가대상 목록 - 화학자재 -->
      <c-table
        ref="table"
        title="LBL0001113"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="process.charmResultScenarios"
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :hideBottom="true"
        :filtering="false"
        :editable="editable&&!disabled"
        selection="multiple"
        rowKey="mdmChemMaterialId"
      >
        <!-- @rowClick="rowClick" -->
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-show="editable&&!disabled&&isSelect" label="LBLADD" icon="add" @btnClicked="openChem" />
            <c-btn v-show="editable&&!disabled&&isSelect" label="LBLREMOVE" icon="remove" @btnClicked="removeChem" />
            <c-btn 
              v-show="editable&&!disabled&&isSelect" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="process.charmResultScenarios"
              mappingType="PUT"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveChem"
              @btnCallback="saveChemCallback" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'charm-target-chem',
  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        workCycleCd: null,  // 작업발생주기코드
        workingTime: 0,  // 작업시간(1회)
        temperature: null,
        workMeasurementPlanId: '',
        measPlanContent: '',
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        charmResultScenarios: [],
      }),
    },
    plantCd: {
      type: String,
      default: function() {
        return '';
      },
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '550px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
    };
  },
  computed: {
    isSelect() {
      return this.process 
        && this.process.processCd
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' ? true : false
    },
    checkProcessCd() {
      return this.process.processCd.indexOf('WTE') === -1
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.charm.scenario.result.url
      this.saveUrl = transactionConfig.ram.charm.scenario.target.url
      this.deleteUrl = transactionConfig.ram.charm.scenario.delete.url
      // code setting
      this.$comm.getComboItems('VOLUME_UNIT').then(_result => {
        this.grid.columns = [
          {
            name: 'materialName',
            field: 'materialName',
            // 화학자재
            label: 'LBL0000356',
            align: 'left',
            style: 'width:40%',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 제조 또는 사용여부
            label: 'LBL0001115',
            align: 'center',
            style: 'width:15%',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
          {
            name: 'usePurpose',
            field: 'usePurpose',
            // 사용온도(℃)
            label: 'LBL0001075',
            align: 'left',
            style: 'width:20%',
            sortable: false,
            type: 'text'
          },
          {
            name: 'daily',
            field: 'daily',
            // 일취급
            label: 'LBL0001114',
            child: [
              {
                name: 'dailyVolume',
                field: 'dailyVolume',
                // 양
                label: 'LBLVOLUME',
                align: 'left',
                style: 'width:15%',
                sortable: false,
                required: true,
                type: 'number'
              },
              {
                name: 'volumeUnit',
                field: 'volumeUnit',
                // 단위
                label: 'LBLUNIT',
                align: 'left',
                style: 'width:10%',
                sortable: false,
                required: true,
                type: 'select',
                comboItems: _result
              },
            ]
          },
          // {
          //   name: 'msdsFlag',
          //   field: 'msdsFlag',
          //   label: 'MSDS 보유여부',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N'
          // },
        ]
      });
      // list setting
    },
    getChems() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd
      }
      this.$http.request((_result) => {
        this.process.charmResultScenarios = _result.data
      },);
    },
    openChem() {
      this.popupOptions.title = 'LBL0000356'; // 화학자재
      this.popupOptions.param = {
        type: 'multiple',
        processCd: this.checkProcessCd ? this.process.processCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/chemMaterialProcessPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChemPopup;
    },
    closeChemPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.process.charmResultScenarios, { mdmChemMaterialId: item.mdmChemMaterialId }) === -1) {
            this.process.charmResultScenarios.splice(0, 0, {
              ramCharmResultAssessScenarioId: uid(),  // 시나리오 일련번호_시나리오의 한 라인(원일별)

              mdmChemMaterialId: item.mdmChemMaterialId,
              materialName: item.materialName,
              useFlag: item.useFlag,
              usePurpose: item.usePurpose,
              dailyVolume: item.dailyVolume,
              volumeUnit: item.volumeUnit,
              // msdsFlag: item.msdsFlag,

              ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,  // 평가 일련번호
              processCd: this.process.processCd,  // 단위공정 코드
              processName: this.process.processName,
              ramTechniqueCd: 'RT00000030',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        })
        // this.saveChem();
      }
    },
    saveChem() {
      if (this.$comm.validTable(this.grid.columns, this.process.charmResultScenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.process.charmResultScenarios, sop => {
              sop.chgUserId = this.$store.getters.user.userId
            });

            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveChemCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveProcess', this.process.processCd)
      this.getChems();
      /**
       * 계획정보수정
       *  변경사항을 적용하기 위함
       */
      if (this.updateBtnData.flag) {
        this.updateBtnData.research = uid();
      }
    },
    removeChem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let message = 'MSGREMOVE'; // 삭제하시겠습니까?
        if (this.updateBtnData.flag) {
          // 현재 평가실행 중인 화학자재입니다.\n\r관련되어 있는 정보 및 개선사항, 위험등록부가 같이 삭제됩니다.\n\r진행하시겠습니까?
          message = 'MSG0000393'
        }
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: message,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.process.charmResultScenarios = this.$_.reject(this.process.charmResultScenarios, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
              
              /**
               * 계획정보수정
               *  변경사항을 적용하기 위함
               */
              if (this.updateBtnData.flag) {
                this.updateBtnData.research = uid();
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>